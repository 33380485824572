import { Icon } from "@iconify/react";
import FrontText from "../FrontText";
import React, { useEffect, useState } from "react";
import { Link, router } from "@inertiajs/react";
import { useTranslation } from "react-i18next";

const FrontSearcherFilters = ({ visible, regions, cities, warehouses, setSelected }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    const checkboxStyles = 'h-[17px] w-[17px] rounded-sm border border-standard-black bg-transparent focus:ring-2 focus:ring-dark-blue-standard focus:ring-offset-white focus:ring-offset-2 focus:border-none text-dark-blue-standard transition-all ease-in-out duration-200 cursor-pointer'

    const [isCitiesVisible, setIsCitiesVisible] = useState({});
    const [selectedProvinces, setSelectedProvinces] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);

    /* =================== [Filters visible for users] =================== */
    const [activeProvinces, setActiveProvinces] = useState([]);
    const [activeCities, setActiveCities] = useState([]);
    const [activeFilters, setActiveFilters] = useState();

    const handleProvinceChange = (event, regionSlug, regionName) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedProvinces([...selectedProvinces, regionSlug]);
            setActiveProvinces([...activeProvinces, regionName]);
        } else {
            setSelectedProvinces(selectedProvinces.filter(name => name !== regionSlug));
            setActiveProvinces(activeProvinces.filter(name => name !== regionName));
        }
    };

    const handleCityChange = (event, citySlug, cityName) => {
        const isChecked = event.target.checked;
        
        if (isChecked) {
            setSelectedCities([...selectedCities, citySlug]);
            setActiveCities([...activeCities, cityName]);
        } else {
            setSelectedCities(selectedCities.filter(name => name !== citySlug));
            setActiveCities(activeCities.filter(name => name !== cityName));
        }
    };

    useEffect(() => {
        setSelected(selectedProvinces, selectedCities);

        setActiveFilters([...activeProvinces, ...activeCities]);
    }, [selectedProvinces, selectedCities]);

    return (
        <>
            {!visible && (
                <div className="absolute md:-top-14 -top-11 left-0 bg-white md:h-14 h-11 w-full rounded-r-10 pointer-events-none select-none flex items-center">
                    <div className={`${activeFilters && activeFilters.length > 0 ? 'text-standard-black' : 'text-gray-text'} font-medium italic text-14 md:pl-c15 pl-2 md:pr-5 pr-2 md:py-c17 py-c11 leading-c17 rounded-r-12 whitespace-nowrap overflow-hidden overflow-ellipsis`}>
                        <span>
                            {activeFilters && activeFilters.length > 0 ? activeFilters.map((el, index) => (
                                <React.Fragment key={index}>
                                    {el}{index +1 !== activeFilters.length && ', '}
                                </React.Fragment>
                            )) : (
                                <span>
                                    {t('search_form.location_placeholder')}
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            )}
            <div className={`absolute -top-0.5 w-full left-0 pr-3 z-10 transition-all ease-in-out duration-300 overflow-hidden ${!visible ? 'h-0 pointer-events-none' : 'h-80 pointer-events-auto'}`}>
                <div className="w-full h-80 overflow-scroll bg-white border-yellow border-2 rounded-b-20 border-t-0">
                    <div className="bg-white px-3 pt-4 pb-0.5">
                        {(regions !== undefined && regions.length > 0) &&
                            <>
                                <FrontText textSize='14/17' className="font-bold mt-3 mb-4">
                                    <span>{t('search_form.provinces')}</span>
                                </FrontText>
                                {regions.map((region, index) => (
                                    <div key={index} className="mb-c18">
                                        <div className="flex justify-between items-center">
                                            <div className="flex gap-x-2">
                                                <input
                                                    className={checkboxStyles}
                                                    type="checkbox"
                                                    name='province[]'
                                                    id={'province' + '-' + index}
                                                    value={currentLanguage === 'pl' ? region.slug : region.slug_en}
                                                    checked={selectedProvinces.includes(currentLanguage === 'pl' ? region.slug : region.slug_en)}
                                                    onChange={event => handleProvinceChange(event, currentLanguage === 'pl' ? region.slug : region.slug_en, (i18n.resolvedLanguage === 'pl' ? region.name : region.name_en))}
                                                />
                                                <label htmlFor={'province' + '-' + index}>
                                                    <FrontText textSize='14/17' className="font-bold">
                                                        <span className="overflow-wrap-anywhere-only-mobile">{currentLanguage === 'pl' ? region.name : region.name_en}</span>
                                                    </FrontText>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        {(cities !== undefined && cities.length > 0) &&
                            <>
                                <hr />
                                <FrontText textSize='14/17' className="font-bold mt-3 mb-4">
                                    <span>{t('search_form.cities')}</span>
                                </FrontText>
                                {cities.map((city, index) => (
                                    <div className="flex gap-x-2 mb-c18" key={city.city+index}>
                                        <input
                                            className={checkboxStyles}
                                            type="checkbox"
                                            name='city[]'
                                            id={'city' + '-' + index}
                                            value={city.city_slug}
                                            checked={selectedCities.includes(city.city_slug)}
                                            onChange={event => handleCityChange(event, city.city_slug, (i18n.resolvedLanguage === 'pl' ? city.city : city.city))}
                                        />
                                        <label htmlFor={'city' + '-' + index}>
                                            <FrontText textSize='14/17'>
                                                <span className="overflow-wrap-anywhere-only-mobile">{city.city}</span>
                                            </FrontText>
                                        </label>
                                    </div>
                                ))}
                            </>
                        }
                        {(warehouses !== undefined && warehouses.length > 0) &&
                            <>
                                <hr />
                                <FrontText textSize='14/17' className="font-bold mt-3 mb-4">
                                    <span>{t('search_form.warehouses')}</span>
                                </FrontText>
                                {warehouses.map((warehouse, index) => (
                                    <div className="flex gap-x-2 mb-c18" key={warehouse.slug+warehouse.id+warehouse.location}>
                                        <FrontText textSize='14/17' className="[&_a]:font-semibold">
                                                <Link title={`Zobacz magazyn ${warehouse.name}`} href={i18n.resolvedLanguage == 'pl' ? route('front.warehouses.single', { province: warehouse.get_region.slug, slug: warehouse.slug }) : route('front.warehouses.single.en', { province: warehouse.get_region.slug_en, slug: warehouse.slug })} className="flex items-center ">
                                                    <span className="overflow-wrap-anywhere-only-mobile">{warehouse.name}</span>
                                                    <Icon
                                                        icon="material-symbols:arrow-right-alt"
                                                        className="text-black icon-wrapper transition-all ease-in-out duration-300 ml-2"
                                                        width={27}
                                                        height={24}
                                                    />
                                                </Link>
                                        </FrontText>
                                    </div>
                                ))}
                            </>
                        }
                        {((warehouses && warehouses.length === 0) && cities.length === 0 && regions.length === 0) &&
                            <FrontText textSize='14/17' className="[&_a]:font-semibold text-tango-red font-bold mt-3 mb-4">
                                <span className="overflow-wrap-anywhere-only-mobile">{t('search_form.noResults')}</span>
                            </FrontText>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default FrontSearcherFilters;