import { Icon } from "@iconify/react";
import Spinner from "@/Components/System/Spinner/spinner";
import FrontText from "../FrontText";

const FrontSmallContactButton = ({
    className = "",
    type = "button",
    children,
    disabled,
    displayAsButton = false,
    ...props
}) => {
    const styles = `inline-flex bg-yellow text-black hover:text-white hover:bg-blue-classic items-center sm:px-5 px-2 py-[10px] rounded-4 gap-[10px] transition ease-in-out duration-150 hover:opacity-90 font-bold ${
        disabled && "opacity-50 cursor-not-allowed"
    } ${className}`
    if (displayAsButton) {
        return (
            <button
                {...props}
                type={type}
                className={styles}
                disabled={disabled}
            >
                <Icon icon={'material-symbols:call'} width={20} height={20} className="shrink-0"/>
    
                <FrontText textSize={"14/17"}>
                    {children}
                </FrontText>
            </button>
        );
    } else {
        return (
            <div
                {...props}
                className={styles}
            >
                <Icon icon={'material-symbols:call'} width={20} height={20} className="shrink-0"/>

                <FrontText textSize={"14/17"}>
                    {children}
                </FrontText>
            </div>
        )
    }
};

export default FrontSmallContactButton;
