import FrontText from "../FrontText";

const FrontPrimaryButton = ({
    className = "",
    type = "button",
    children,
    disabled,
    outline = false,
    displayAsButton = false,
    ...props
}) => {
    const styles = `inline-flex ${outline ? 'bg-transparent hover:text-light-blue hover:border-light-blue text-yellow border-yellow hover:bg-yellow' : 'bg-yellow text-light-blue border-light-blue hover:text-yellow hover:border-yellow hover:bg-transparent'}  items-center sm:px-4 px-2 min-w-[160px] py-3 md:min-h-14 min-h-11 border rounded-lg gap-[10px] transition ease-in-out duration-150 hover:opacity-90 justify-center ${
        disabled && "opacity-50 cursor-not-allowed"
    } ${className}`

    if (displayAsButton) {
        return (
            <button
                {...props}
                type={type}
                disabled={disabled}
                className={styles}
            >
                <FrontText textSize={"14/17"} className="font-semibold">
                    {children}
                </FrontText>
            </button>
        );
    } else {
        return (
            <div
                {...props}
                className={styles}
            >
                <FrontText textSize={"14/17"} className="font-semibold">
                    {children}
                </FrontText>
            </div>
        );
    }
};

export default FrontPrimaryButton;
